<script setup>
import { ref, onMounted, watch, inject } from "vue";
import { useRoute } from 'vue-router';
import { getPageDataService } from '@/utils/sitefinityAPI';
// SVGs
import LogoTKMaxx from '@/components/svgs/LogoTKMaxx.vue'
import IconPin from '@/components/svgs/IconPin.vue'
const isMobile = ref(inject('isMobile'));
const route = useRoute();
const primaryNav = ref({});
const dataLoading = ref(true);

const initPageData = async () => {
  try {
    primaryNav.value = await getPageDataService('contentitems', "UrlName eq 'primary-nav'");
    dataLoading.value = false;
  } catch (error) {
    console.log('error loading page data', error);
  }
};

// Function to update active class based on the current route
const updateActiveLink = () => {
  const currentPath = route.path.split('/').filter(Boolean).pop();
  const navLinks = document.querySelectorAll('nav li a');

  navLinks.forEach(link => {
    const linkPath = link.pathname.split('/').filter(Boolean).pop();
    if (linkPath === currentPath) {
      link.parentElement.classList.add('active');
    } else {
      link.parentElement.classList.remove('active');
    }
  });
};

const mobileNavOpen = ref(false);

const toggleMobileNav = () => {
  const navBurg = document.querySelector('.nav-burg');
  const navMenu = document.querySelector('.nav-menu');

  mobileNavOpen.value = mobileNavOpen.value ? false : true;
  if (mobileNavOpen.value) {
    navBurg.classList.add('open');
    navMenu.classList.add('open');
  } else {
    navBurg.classList.remove('open');
    navMenu.classList.remove('open');
  }
};

// Run the function on initial load
onMounted(() => {
  initPageData();
  updateActiveLink();
});

// Watch for route changes and update the active link
watch(route, () => {
  updateActiveLink();

  if (mobileNavOpen.value) {
    toggleMobileNav(true);
  }
});
</script>

<template>
  <nav class="nav">
    <div class="container">
      <Transition name="fade">
      <div class="nav-wrap" v-if="!dataLoading">
        <a class="nav-burg" href="javascript:void(0)" @click.prevent="toggleMobileNav()">
          <span class="nav-burg-line nav-burg-line--top"></span>
          <span class="nav-burg-line nav-burg-line--mid"></span>
          <span class="nav-burg-line nav-burg-line--bot"></span>
        </a>
        <div class="nav-logo">
          <RouterLink to="/" class="nav-logo-link">
            <LogoTKMaxx />
          </RouterLink>
        </div>
        <Transition name="fade">
        <div class="nav-menu" v-show="mobileNavOpen || !isMobile" v-router-links v-html="primaryNav.Content">
        </div>
        </Transition>
        <!-- <div class="nav-subscribe">
          <a href="/subscribe" class="button transparent">Subscribe</a>
        </div> -->
        <RouterLink to="/store-locator" class="nav-locator">
          <IconPin />
        </RouterLink>
      </div>
      </Transition>
    </div>
  </nav>
</template>

<style lang="scss" type="text/scss" scoped>

.nav {
  z-index: $z-index-nav;
  background-color: $body-color;
  height: $nav-height;
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid $orange;
}

.nav-wrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: $nav-height;
}

.nav-logo {
  grid-area: 1 / 5 / 2 / 9;
  background-color: transparent;
  z-index: 3;
  background-color: $red;

  @media (min-width: $grid-float-breakpoint) {
    grid-area: 1 / 1 / 2 / 3;
  }
}

.nav-logo-link {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: ($nav-height - 1px);
  padding: 0;
  padding-top: 4px;
}

.nav-menu {
  @media (max-width: $grid-float-breakpoint-max) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 2;
  }

  @media (min-width: $grid-float-breakpoint) {
    display: block;
    grid-area: 1 / 3 / 2 / 13;
    height: $nav-height;
  }
}

:deep(.primary-nav) {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $grid-float-breakpoint-max) {
    flex-direction: column;
    justify-content: center;
    text-align: left;
    min-width: 250px;

    a {
      color: #fff;
      font-size: 2.6rem;
      line-height: 5rem;
      font-family: "GT Walsheim Pro Black", sans-serif;
    }

    .sub-nav {
      display: none;
    }
  }

  > li {
    width: 100%;
    height: $nav-height;
  }

  li {
    list-style: none;
    transition: all 0.2s ease 0s;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    @media (min-width: $grid-float-breakpoint) {
      &.active {
        border-bottom: 6px solid $orange;
      }
    }

    &:hover {
      .sub-nav {
        animation: menuSlideDown 0.2s linear 0s forwards;
      }
    }
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    
    @media (min-width: $grid-float-breakpoint) {
      justify-content: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.nav :deep(#subscribe a) {
  @media (min-width: $grid-float-breakpoint) {
    border: 1px solid $grey;
    background: transparent;
    border-radius: 2px;
    padding: 4px 10px;
    text-align: center;
    display: block;
    height: 30px;
    width: 120px;
    margin: 0 auto;
    margin-top: 8px;
    font-weight: normal;
    color: $red;
    text-decoration: none !important;
    transition: all 0.3s ease;
    max-width: 120px;

    &:hover {
      background: $red;
      color: #fff;
    }
  }
}

.nav :deep(.sub-nav) {
  z-index: 1;
  background: transparent;
  position: fixed;
  top: $nav-height;
  width: 100%;
  left: 0px;
  height: 0px;
  overflow: hidden;

  > div {
    background-color: #fff;
  }

  ul {
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
  }

  li {
    height: 40px;
    width: 100%;
    max-width: 200px;
  }
}

.nav-burg {
  width: 30px;
  display: block;
  position: fixed;
  cursor: pointer;
  height: 30px;
  z-index: 3;
  top: 20px;
  left: 15px;

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }

  .nav-burg-line {
    z-index: 5;
    display: block;
    background: darkGrey;
    height: 2px;
    width: 28px;
    position: absolute;
    transition: all 0.5s ease;
  }

  .nav-burg-line--top {
    top: 0px;
  }

  .nav-burg-line--mid {
    top: 8px;
  }

  .nav-burg-line--bot {
    top: 16px;
  }

  &.open {
    .nav-burg-line--top {
      transform: rotatez(45deg);
      -webkit-transform-origin: 0%;
      transform-origin: 0%;
      left: 2px;
      background-color: #fff;
    }

    .nav-burg-line--mid {
      opacity: 0;
      background-color: #fff;
    }

    .nav-burg-line--bot {
      transform: rotatez(-45deg);
      -webkit-transform-origin: 20%;
      transform-origin: 20%;
      background-color: #fff;
    }
  }
}

.nav-locator {
  position: absolute;
  top: 12px;
  right: 10px;
  color: darkGrey;
  z-index: 1;

  svg {
    height: 36px;
    width: 36px;
  }
  
  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .mobile-menu-overlay {
    display: none;
  }
}

@keyframes menuSlideDown {
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
}
</style>