import { ref } from 'vue';
import { useRouter } from 'vue-router';

export function useGoogleAnalytics() {
  const eventLog = ref([]);

  // Initialize the dataLayer if it doesn't exist
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  const trackPage = (url) => {
    if (!url) {
      const router = useRouter();
      url = router.currentRoute.value.fullPath;
    }

    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: url
      }
    });
  };

  const trackEvent = (eventCategory, eventAction, eventLabel) => {
    eventLog.value.push(`send.event.${eventCategory},${eventAction},${eventLabel}`);
    window.dataLayer.push({
      event: 'event',
      category: eventCategory,
      action: eventAction,
      label: eventLabel
    });
  };

  const track = (...args) => {
    eventLog.value.push(`send.${args.join('.')}`);
    // Optionally, add the arguments to the dataLayer or call your GA function
  };

  const log = () => {
    console.log(eventLog.value);
  };

  return {
    trackPage,
    trackEvent,
    track,
    log
  };
}
