<script setup>
import { useGoogleAnalytics } from '@/composables/useGoogleAnalytics';
const { trackEvent } = useGoogleAnalytics();

const trackClick = function(target){
  trackEvent('Footer', target, 'click');
}

// SVGs
import IconFacebook from './svgs/IconFacebook.vue'
import IconInstagram from './svgs/IconInstagram.vue'
import IconXTwitter from './svgs/IconXTwitter.vue'
import IconYouTube from './svgs/IconYouTube.vue'
</script>

<template>
  <p>Get social with us</p>

  <div class="social-icons">
    <a @click="trackClick('facebook')" href="https://www.facebook.com/TKMaxxAU" target="_blank">
      <IconFacebook />
    </a>
    <a @click="trackClick('instagram')"  href="https://www.instagram.com/tkmaxxau/" target="_blank">
      <IconInstagram />
    </a>
    <a @click="trackClick('twitter')" href="https://twitter.com/tkmaxxau" target="_blank">
      <IconXTwitter />
    </a>
    <a @click="trackClick('youtube')" href="https://www.youtube.com/channel/UCpiaWKE2qefCwWqPNNWnaMQ" target="_blank">
      <IconYouTube />
    </a>
  </div>
</template>