import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/',
    component: () => import( '@/pages/HomePage.vue')
  },
  { path: '/get-inspired',
    component: () => import('@/pages/GetInspired.vue')
  },
  { path: '/store-locator/:storeID?',
    // meta { scrollToElement: '#topStoreLocator' },
    component: () => import('@/pages/StoreLocator.vue')
  },
  { path: '/fan-finds',
    component: () => import('@/pages/FanFinds.vue')
  },
  { path: '/subscribe',
    component: () => import('@/pages/SubscribePage.vue')
  },
  { path: '/contact',
    component: () => import('@/pages/ContactUs.vue')
  },
  { path: '/thankyou',
    component: () => import('@/pages/SubscribeConfirmation.vue')
  },
  { path: '/faqs',
    component: () => import('@/pages/FAQPage.vue')
  },
  { path: '/new-store-openings',
    component: () => import('@/pages/NewStoreOpenings.vue')
  },
  { path: '/email-contest',
    component: () => import('@/pages/EmailContest.vue')
  },
  { path: '/corporate-responsibility',
    component: () => import('@/pages/CorporateResponsibility.vue')
  },
  { path: '/:dir/:subDir?',
    component: () => import('@/pages/ContentPage.vue')
  },
  { path: '/:catchall(.*)*',
    alias: '/not-found',
    name: 'Page Not Found',
    component: () => import('@/pages/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Handle scrolling within the store-locator pages
    if (to.path.includes('/store-locator')) {
      // If there is a saved position (e.g., the user used back/forward navigation), keep it
      if (savedPosition) {
        return savedPosition;
      }
      // If navigating between store-locator pages, do nothing (stay at the same position)
      if (from.path.includes('/store-locator') && to.path.includes('/store-locator')) {
        return false;
      }
      // If the page is refreshed or accessed directly, scroll to the top
      return { top: 0 };
    }

    // Default behavior for other pages
    // if (to.meta.scrollToElement) {
    //   return {
    //     el: to.meta.scrollToElement,
    //     top: 150, // Adjust the scroll offset as needed
    //     behavior: 'smooth',
    //   };
    // }

    // Restore saved scroll position for other pages
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router
