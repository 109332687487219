export default {
  mounted(el, binding) {
    const router = binding.value;

    const clickHandler = (event) => {
      if (event.target.tagName === 'A') {
        const href = event.target.getAttribute('href');
        const isExternal = /^https?:\/\//.test(href) && !href.includes('tkmaxx.com.au');
        const isAnchor = /^#/.test(href);

        if (!isExternal && !isAnchor) {
          event.preventDefault();
          router.push(href);
        }
      }
    };

    el.addEventListener('click', clickHandler);

    el._clickHandler = clickHandler;
  },
  unmounted(el) {
    el.removeEventListener('click', el._clickHandler);
    delete el._clickHandler;
  },
};

