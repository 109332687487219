
<script>
</script>

<template>
  <div class="loadingSpinner">
    <!-- loading -->
  </div>
</template>


<style lang="scss" type="text/scss" scoped>
.loadingSpinner {
  width: 60px !important;
  height: 60px !important;
  background: url(@/assets/images/tkmaxx_colours.gif) no-repeat;
  border-radius: 100%;
  background-position: right;
  animation: loading_spinner_animation 3s linear infinite;
  margin: 20px auto;
}

@keyframes loading_spinner_animation {
  0% { background-position: 0 0; transform:rotate(0deg);}
  16.6% { background-position: -100 0; transform:rotate(0deg);}
  16.7% { transform:rotate(60deg);}
  33% { background-position: -200 0; transform:rotate(60deg);}
  33.1% { transform:rotate(120deg);}
  50% { background-position: -300 0; transform:rotate(120deg);}
  50.1% { transform:rotate(180deg);}
  66.6% { background-position: -400 0; transform:rotate(180deg);}
  66.7% { transform:rotate(240deg);}
  83% { background-position: -500 0; transform:rotate(240deg);}
  83.1% { transform:rotate(300deg);}
  99.9% { background-position: -600 0; transform:rotate(300deg);}
  100% { transform:rotate(0deg);}
}
</style>