<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.5 90.3" style="enable-background:new 0 0 256.5 90.3" xml:space="preserve">
    <path class="st0" d="M0-.4h256.5v90.7H0z"/>
    <path class="st1" d="M61.3 43.3c0-2.6-2.1-4.7-4.7-4.7s-4.7 2.1-4.7 4.7S54 48 56.6 48s4.7-2.1 4.7-4.7M87.8 38.6c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7"/>
    <path class="st1" d="M59 25.5H33.3v7.9h8.5v20.7h8.7V33.4H59zM123.8 25.1c-3.3 0-6.3 1.3-8.5 3.5-2.2-2.2-5.2-3.5-8.6-3.5-6.6 0-12.8 5.4-12.8 12v17.1h8.6V37.1c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2v17.1h8.7V37.1c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2v17.1h8.6v-17c-.1-6.7-6.2-12.1-12.8-12.1M205.1 42.8l9.6-12.5h-10.9l-4.1 5.4-4.2-5.4H184l9.9 12.9-11.1 14.5H194l5.7-7.7 12.4 16.2H223z"/>
    <path class="st1" d="M181.5 29.2h1.4l-2.8-3.7h-11l9.8 12.8-9.8 12.7V25.6h-8.6v1.2c-2.2-1.3-4.7-2-7.5-2-8.3 0-15.1 6.8-15.1 15.1S144.7 55 153 55c2.7 0 5.3-.8 7.5-2.1v1.3h17.6l6.5-8.6 2.9 3.7 4.8-6.2-10.8-13.9zm-28.4 17.6c-3.8 0-6.9-3.1-6.9-6.9 0-3.8 3.1-6.9 6.9-6.9 3.8 0 6.9 3.1 6.9 6.9 0 3.8-3.1 6.9-6.9 6.9"/>
    <path class="st2" d="M62.6 25.5h9v12.3l6.4-7.3h10.8l-9.3 12.4L97.3 66H85.7L71.6 48.6v5.5h-9zM189.7 25.6l-2.8 3.6h9.4l.9 1.2 3.7-4.8z"/>
  </svg>
</template>
<style scoped>
  svg {
    width: 100%;
  }
</style>