<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 141.7 141.7"
    style="enable-background: new 0 0 141.7 141.7"
    xml:space="preserve"
  >
    <circle class="st0" cx="70.9" cy="71.3" r="40.7" />
    <path
      class="st1"
      d="M70.9 52.9c6.3 0 7 0 9.5.1 2.3.1 3.5.5 4.4.8 1.1.4 1.9.9 2.7 1.8.8.8 1.3 1.6 1.8 2.7.3.8.7 2.1.8 4.4.1 2.5.1 3.2.1 9.5s0 7-.1 9.5c-.1 2.3-.5 3.5-.8 4.4-.4 1.1-.9 1.9-1.8 2.7-.8.8-1.6 1.3-2.7 1.8-.8.3-2.1.7-4.4.8-2.5.1-3.2.1-9.5.1s-7 0-9.5-.1c-2.3-.1-3.5-.5-4.4-.8-1.1-.4-1.9-.9-2.7-1.8-.8-.8-1.3-1.6-1.8-2.7-.3-.8-.7-2.1-.8-4.4-.1-2.5-.1-3.2-.1-9.5s0-7 .1-9.5c.1-2.3.5-3.5.8-4.4.4-1.1.9-1.9 1.8-2.7.8-.8 1.6-1.3 2.7-1.8.8-.3 2.1-.7 4.4-.8 2.5-.1 3.2-.1 9.5-.1m0-4.2c-6.4 0-7.2 0-9.7.1-2.5.1-4.2.5-5.7 1.1-1.5.6-2.9 1.4-4.2 2.7-1.3 1.3-2.1 2.6-2.7 4.2-.6 1.5-1 3.2-1.1 5.7-.1 2.5-.1 3.3-.1 9.7 0 6.4 0 7.2.1 9.7.1 2.5.5 4.2 1.1 5.7.6 1.5 1.4 2.9 2.7 4.2 1.3 1.3 2.6 2.1 4.2 2.7 1.5.6 3.2 1 5.7 1.1 2.5.1 3.3.1 9.7.1 6.4 0 7.2 0 9.7-.1 2.5-.1 4.2-.5 5.7-1.1 1.5-.6 2.9-1.4 4.2-2.7 1.3-1.3 2.1-2.6 2.7-4.2.6-1.5 1-3.2 1.1-5.7.1-2.5.1-3.3.1-9.7 0-6.4 0-7.2-.1-9.7-.1-2.5-.5-4.2-1.1-5.7-.6-1.5-1.4-2.9-2.7-4.2-1.3-1.3-2.6-2.1-4.2-2.7-1.5-.6-3.2-1-5.7-1.1-2.5-.1-3.3-.1-9.7-.1"
    />
    <path
      class="st1"
      d="M70.9 60.1c-6.7 0-12.1 5.4-12.1 12.1 0 6.7 5.4 12.1 12.1 12.1S83 78.9 83 72.2c0-6.7-5.4-12.1-12.1-12.1m0 19.9c-4.3 0-7.8-3.5-7.8-7.8s3.5-7.8 7.8-7.8 7.8 3.5 7.8 7.8-3.5 7.8-7.8 7.8M86.3 59.6c0 1.6-1.3 2.8-2.8 2.8-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8 1.5 0 2.8 1.3 2.8 2.8"
    />
  </svg>
</template>
