<script setup>
import { onMounted, ref, provide, onBeforeUnmount, watchEffect } from "vue";
import GlobalNav from '@/components/GlobalNav.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useRoute, RouterView } from 'vue-router';
import { useMetaDataService } from '@/composables/useMetaDataService';
import { useGoogleAnalytics } from '@/composables/useGoogleAnalytics';
const { trackPage } = useGoogleAnalytics();

const isMobile = ref(false);
const isTouch = ref(false);
const route = useRoute();
const { setPage, metaTitle, metaDescription } = useMetaDataService();

// Watch for route changes and update title/meta description
watchEffect(() => {
  setPage(route.path); // Update metadata based on route
  document.title = metaTitle(); // Update the document's title
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', metaDescription()); // Update the meta description

  trackPage(route.path); // track page change
});

const detectMobileAndTouch = () => {
  const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  isMobile.value = window.innerWidth <= 768 || mobileUserAgent.test(navigator.userAgent);
  isTouch.value = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  return { isMobile, isTouch };
};

provide('isMobile', isMobile);
provide('isTouch', isTouch);

onBeforeUnmount(() => {
  window.removeEventListener('resize', detectMobileAndTouch);
});

onMounted(() => {
  detectMobileAndTouch();
  window.addEventListener('resize', detectMobileAndTouch);
  isMobile.value ? document.body.classList.add('isMobile') : null;
  isTouch.value ? document.body.classList.add('isTouch') : null;
});
</script>

<template>
  <Suspense>
    <template #default>
      <GlobalNav />
    </template>
    <template #fallback>
      <LoadingSpinner />
    </template>
  </Suspense>

  <Suspense>
    <template #default>
      <div class="main">
        <RouterView />
      </div>
    </template>
    <template #fallback>
      <LoadingSpinner />
    </template>
  </Suspense>

  <Suspense>
    <template #default>
      <GlobalFooter />
    </template>
    <template #fallback>
      <LoadingSpinner />
    </template>
  </Suspense>
</template>

<style lang="scss" type="text/scss">
#app {
  padding-top: $nav-height; // Height of the header
}

.main {
  min-height: calc(100vh - ($nav-height + $footer-min-height - 1px)); // 1px extra to keep scrollbar visible. 
}

// .loading-bar {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 4px;
//   background-color: #3498db;
//   z-index: 9999;
//   animation: loading 2s linear infinite;
// }

// @keyframes loading {
//   0% {
//     transform: translateX(-100%);
//   }
//   50% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(100%);
//   }
// }
</style>
