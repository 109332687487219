import { createApp } from 'vue'
import App from './App.vue'
import routerLinksDirective from '@/directives/routerLinks.js';
import router from './router'
import '@/assets/css/main.scss'

import '@/assets/js/gtm.js'
import '@/assets/js/fbpixel.js'

const app = createApp(App)
app.directive('router-links', {
  mounted(el) {
    routerLinksDirective.mounted(el, { value: router });
  },
  unmounted: routerLinksDirective.unmounted,
});
// Update the document title based on the meta title
// router.beforeEach((to, from, next) => {
//   const pageTitle = to.meta.title ? 'TK Maxx Australia - ' + to.meta.title : 'TK Maxx Australia';
//   document.title = pageTitle;
//   next();
// });
app.use(router)
app.mount('#app')