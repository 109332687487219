<script setup>
import { ref, onMounted } from "vue";
import { getPageDataService } from '@/utils/sitefinityAPI';
import GlobalFooterSocial from "./GlobalFooterSocial.vue";

const footerColumn2Data = ref({})
const footerColumn3Data = ref({})
const footerColumn4Data = ref({})
const pageDataLoading = ref(true)

const getPageData = async () => {
  try {
    footerColumn2Data.value = await getPageDataService('contentitems', "UrlName eq 'footer-column-2'");
    footerColumn3Data.value = await getPageDataService('contentitems', "UrlName eq 'footer-column-3'");
    footerColumn4Data.value = await getPageDataService('contentitems', "UrlName eq 'footer-column-4'");
    pageDataLoading.value = false;
  } catch (error) {
    console.log('footerColumnData loading page data', error);
  }
};

// SVGs
import LogoTKMaxx from './svgs/LogoTKMaxx.vue'

onMounted(() => {
  getPageData();
});


</script>

<template>
  <footer>
      <div class="container">
        <Transition name="fade">
        <div class="row" v-if="!pageDataLoading">
          <div class="col-sm-3 col-md-2">
            <div class="footer-logo">
              <LogoTKMaxx />
            </div>
            <GlobalFooterSocial />
          </div>
          <div class="col-sm-3 col-md-3 col-md-offset-1" v-html="footerColumn2Data.Content" v-router-links></div>
          <div class="col-sm-3" v-html="footerColumn3Data.Content" v-router-links></div>
          <div class="col-sm-3" v-html="footerColumn4Data.Content" v-router-links></div>
        </div>
        </Transition>
      </div>
  </footer>
</template>

<style lang="scss" type="text/scss" scoped>
:deep(a) {
  white-space: nowrap;
  text-wrap: none;

  &,
  &:active {
    color: $midGrey;
  }
}

@media (min-width: $screen-sm-min) {
  footer {
    min-height: 350px;
    padding-bottom: 50px;

    .footer-logo {
      width: 100%;
    }
  }
}
</style>